import axios from "axios"
import moment from "moment/moment";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Spinner} from "reactstrap";
import {sessionService} from 'redux-react-session';
import apis from "./config/apis";
// import io from "socket.io-client";
import Conf from "./config/tsconfig.json";
import Home from "./layouts/Home";
import {
    addArtistTypes,
    addCountries,
    addCountryLog,
    addCountryLogId,
    addEventTypes,
    addGlobalCurrencyCode,
    addGlobalCurrencySymbol,
    addIPAddress,
    configAxios,
    setCountryCode, setIsDev,
} from "./reducer";
import {cleanAuthData, fetchUserData} from "./tools/Tools";

function App() {

    const now = moment()
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const auditor = useSelector(state => state.axios.auditor);
    // const socket = io(
    //     !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
    //         ? Conf.configs.socketEndPointDev
    //         : Conf.configs.socketEndPoint
    // );

    const loadSession = async (ipAddress) => {
        await sessionService.loadSession().then((currentSession) => {
            sessionService.loadUser().then(async (data) => {
                let headers = {
                    'Content-Type': 'application/json',
                    'Language': 'fr',
                    'Access-Control-Allow-Origin': "*",
                    'Isl-Token': currentSession.token
                }
                axios.get(apis.isTokenValid, {headers: headers}).then(async () => {
                    // onLineData();
                    await dispatch(configAxios({ipAddress: ipAddress, token: currentSession.token}))
                    await dispatch(addIPAddress(ipAddress))
                    // await fetchUserData(currentSession.token, dispatch, socket);
                    await fetchUserData(currentSession.token, dispatch);
                }).catch(async () => {
                    await sessionService.deleteSession()
                    await sessionService.deleteUser()
                    await cleanAuthData(dispatch)
                    await dispatch(configAxios({ipAddress: ipAddress, token: Conf.configs.TokenVisitor}))
                });
            });
        }).catch(() => {
            // HomeRoot.notOnline();
        });
    }

    const dispatchCountry = (countryChoice) => {
        dispatch(addCountryLog(countryChoice.name))
        dispatch(addCountryLogId(countryChoice.id))
        dispatch(setCountryCode(countryChoice.country_code))
        dispatch(addGlobalCurrencySymbol(countryChoice?.currencies?.symbol));
        dispatch(addGlobalCurrencyCode(countryChoice?.currencies?.code));
    }

    useEffect(() => {

        Promise.all([

            // dispatch(addSocket(socket)),

            auditor.get(apis.getArtistTypes).then(resp => {
                dispatch(addArtistTypes(resp.data.artist_types));
                dispatch(addEventTypes(resp.data.events));
            }).catch(() => {}),

            auditor.get(apis.getAllCountries).then(async r => {
                await dispatch(addCountries(r.data))
                let lastUpdatedDate = moment(JSON.parse(localStorage.getItem("lastUpdatedDate")));
                let elapsedDuration = moment.duration(moment().diff(lastUpdatedDate));
                if (!lastUpdatedDate._isValid || elapsedDuration._data?.hours >= 12) {
                    await axios.get(Conf.configs.IPGeolocationAPI).then(async resp => {
                        let countryChoice = r.data.filter(country =>
                            country['name'].toLowerCase() === resp.data["country"].toLowerCase()
                            || resp.data["country"].toLowerCase() === country['nativeName'].toLowerCase())[0]
                        if (countryChoice) {
                            localStorage.setItem('country', JSON.stringify(countryChoice))
                            localStorage.setItem('lastUpdatedDate', JSON.stringify(now))
                            dispatchCountry(countryChoice);
                        }
                        await loadSession(resp.data["ip_address"])
                    })
                } else {
                    let countryChoice = JSON.parse(localStorage.getItem('country'))
                    dispatchCountry(countryChoice);
                    await loadSession()
                }
            })

        ]).then(() => {
            dispatch(setIsDev(!process.env.NODE_ENV || process.env.NODE_ENV === 'development'))
            setLoading(false)
        });

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [dispatch]);

    return (
        localStorage.getItem('lastUpdatedDate') && loading
            ? <div className="d-flex justify-content-md-center align-items-center vh-100 bg-default-0">
                <div className="flex m-auto">
                    <Spinner type="grow" size="lg"/>
                </div>
            </div>
            : !localStorage.getItem('lastUpdatedDate') && loading
                ? <div className="d-flex justify-content-md-center align-items-center vh-100 bg-default-0">
                    <div className="flex m-auto">
                        <div className="row justify-content-center">
                            <h4 className="text-danger">Bienvenue sur Kantobiz</h4>
                        </div>
                        <hr/>
                        <div className="row mt-4 justify-content-center">
                            <p>Contactez l'artiste idéal à votre événement.</p>
                        </div>
                        <div className="row mt-3 justify-content-center">
                            <p className="text-secondary">Chargement...&nbsp;<Spinner type="grow" size="sm"/></p>
                        </div>
                    </div>
                </div>
                : <Home/>
    );
}

export default App;
