import React, {useEffect, useRef} from "react";
import {Row} from "reactstrap";

function NotFound() {

    const isMounted = useRef(false);

    useEffect(() => {
        return () => {
            isMounted.current = true
        };
    }, []);

    return (
        <div className="content row justify-content-center overflow-auto pt-5" style={{height: "80vh"}}>
            <Row className="justify-content-center card-flex-row overflow-auto bg-transparent border-0"
                 style={{height: "80vh"}}>
                <div className="center">
                    <div className="t-circle">
                    </div>
                    <div className="line">
                    </div>
                    <div className="banner text-center">
                        <h1 className="text-white pt-3">
                            Oops!
                        </h1>
                    </div>
                    <h2 className="mt-4">
                        Erreur 404 page non trouvée
                    </h2>
                </div>
            </Row>
        </div>
    );
}

export default NotFound;
