import {IconButton} from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import React, {useEffect, useState} from "react";
import Box from "@material-ui/core/Box";
import {useSelector} from "react-redux";
import {useWindowSize} from "../../tools/Tools";
import MessengerList from "./Messenger";
import Drawer from '@mui/material/Drawer';
import Badge from "@mui/material/Badge";

function FixedPlugin() {

    const [msgExist, setMsgExist] = useState(false);
    const width = useWindowSize();
    const [state, setState] = useState({right: false});
    const userId = useSelector(state => state.profile.userId);
    const contacts = useSelector(state => state.profile.contacts);
    const contactToChat = useSelector(state => state.global.contactToChat);

    const isNotOpenedChat = () => {
        if (contactToChat === null || contactToChat === undefined || !Object.keys(contactToChat)?.length) {
            return true
        } else if (Object.keys(contactToChat)?.length && !state?.right) {
            return true
        }
        return false
    }

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        if (isNotOpenedChat()) {
            setState({...state, [anchor]: open});
        }
    };

    const checkMessageLength = () => {
        let a = {...contacts};
        let b = a['book']?.accepted
        let c = a['auditor']?.accepted

        if (b?.length) {
            let _tmp = b.filter((book) => (new Date(book?.reservation?.event_date) >= new Date()) && !book.aborted)
            _tmp?.length && setMsgExist(true)
        }

        if (c?.length) {
            let _tmp = c.filter((book) => (new Date(book?.reservation?.event_date) >= new Date()) && !book.aborted)
            _tmp?.length && setMsgExist(true)
        }
    }

    useEffect(() => {
        userId && !msgExist && checkMessageLength()
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [msgExist, userId])

    return (
        <div className="fixed-plugin">
            <Tooltip title="Messages" placement="left">
                <IconButton
                    sx={{p: '15px'}}
                    aria-label="search"
                    id="open_discussion"
                    onClick={toggleDrawer('right', true)}>
                    <Badge variant="dot" color="secondary" invisible={!msgExist}>
                        <QuestionAnswerIcon fontSize="large" sx={{color: "white!important"}}/>
                    </Badge>
                </IconButton>
            </Tooltip>
            <Drawer
                anchor={'right'}
                open={state['right']}
                onClose={toggleDrawer('right', false)}
            >
                <Box sx={{width: width < 500 ? width : 450}} role="presentation">
                    <MessengerList toggleDrawer={toggleDrawer}/>
                </Box>
            </Drawer>
        </div>
    );
}

export default FixedPlugin;
