const initState = {
    role: '',
    notes: '',
    userId: 0,
    banking: {},
    services: [],
    followers: 0,
    followings: 0,
    contacts: {
        'book': {
            'accepted': [],
            'declined': [],
            'pending': []
        },
        'auditor': {
            'accepted': [],
            'declined': [],
            'pending': []
        }
    },
    profileData: {},
    show_banking: false,
    contactsLength: {
        'book': {
            'accepted': 0,
            'declined': 0,
            'pending': 0
        },
        'auditor': {
            'accepted': 0,
            'declined': 0,
            'pending': 0
        }
    },
    notification_length: 0,
    notificationUnRead: [],
    notificationRead: [],
    currencies_banking: [],
    currencies_banking_code: [],
    currencies_banking_original: {},
    conditions: {
        travel_expenses: {
            from: 0,
            to: 0
        },
        refund_policy: "flexible"
    },
};

const ProfileData = (state = initState, action) => {
    switch (action.type) {
        case "ADD_PROFILE_USER_CONTACT":
            return {
                ...state,
                contacts: action.data
            };
        case "ADD_PROFILE_USER_CONTACT_LENGTH":
            return {
                ...state,
                contactsLength: action.data
            };
        case "ADD_PROFILE_USER_ID":
            return {
                ...state,
                userId: action.data
            };
        case "ADD_PROFILE_NOTIFICATIONS_LENGTH":
            return {
                ...state,
                notification_length: action.data
            };
        case "ADD_PROFILE_NOTIFICATIONS_READ":
            return {
                ...state,
                notificationRead: action.data
            };
        case "ADD_PROFILE_NOTIFICATIONS_UNREAD":
            return {
                ...state,
                notificationUnRead: action.data
            };
        case "ADD_PROFILE_DATA":
            return {
                ...state,
                profileData: action.data
            };
        case "ADD_PROFILE_CONDITIONS":
            return {
                ...state,
                conditions: action.data
            };
        case "ADD_PROFILE_BANKING":
            return {
                ...state,
                banking: action.data
            };
        case "ADD_PROFILE_ROLE":
            return {
                ...state,
                role: action.data
            };
        case "ADD_PROFILE_NOTES":
            return {
                ...state,
                notes: action.data
            };
        case "ADD_PROFILE_FOLLOWERS":
            return {
                ...state,
                followers: action.data
            };
        case "ADD_PROFILE_FOLLOWINGS":
            return {
                ...state,
                followings: action.data
            };
        case "ADD_PROFILE_BANKING_SHOW":
            return {
                ...state,
                show_banking: action.data
            };
        case "ADD_PROFILE_BANKING_CURRENCIES":
            return {
                ...state,
                currencies_banking: action.data
            };
        case "ADD_PROFILE_BANKING_CURRENCIES_CODE":
            return {
                ...state,
                currencies_banking_code: action.data
            };
        case "ADD_PROFILE_BANKING_CURRENCIES_ORIGINAL":
            return {
                ...state,
                currencies_banking_original: action.data
            };
        case "ADD_PROFILE_SERVICES":
            return {
                ...state,
                services: action.data
            };
        default:
            return state;
    }
};

export default ProfileData;
