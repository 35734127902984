import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import {useGmailListItemStyles} from '@mui-treasury/styles/listItem/gmail';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AddTaskIcon from '@mui/icons-material/AddTask';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import {IconButton} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import moment from 'moment';
import React, {useEffect, useState} from "react";
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import {CardFooter, CardHeader, CardTitle, Col, Row, Spinner} from "reactstrap";
import {addProfileDataContacts} from "../../reducer";
import NoDataList from "../../tools/NoDataList";
import {btnStyle, checkErrorMessage, getContact, getLength} from "../../tools/Tools";
import SkeletonContactCardHeader from "../skeletonCustom/SkeletonContactCardHeader";
import SkeletonNavContactCardList from "../skeletonCustom/SkeletonNavContactCardList";
import ContactCard from "./ContactCard";
import DashboardHeader from "../reservations/DashboardHeader";
import ContactCardHeader from "./ContactCardHeader";


function ContactCardList({bookedList}) {

    let now = moment()
    const dispatch = useDispatch();
    const auditor = useSelector(state => state.axios.auditor);
    const tabIndex = useSelector(state => state.contactCards.tabIndex);
    const [booked, setBooked] = useState([]);
    const [load, setLoad] = useState(false);
    const [toLoad, setToLoad] = useState(false);
    const [warnDecline, setWarnDecline] = useState(false);
    const [loading, setLoading] = useState(true);
    const [activeLine, setActiveLine] = useState(1);
    const [toShow, setToShow] = useState(false);
    const [showBook, setShowBook] = useState({});
    const [pendingPage, setPendingPage] = useState(1);
    const [refusedPage, setRefusedPage] = useState(1);
    const [acceptedPage, setAcceptedPage] = useState(1);
    const [pendingMoreActive, setPendingMoreActive] = useState(false);
    const [refusedMoreActive, setRefusedMoreActive] = useState(false);
    const [acceptedMoreActive, setAcceptedMoreActive] = useState(false);
    const link = useSelector(state => bookedList ? 'book' : 'auditor');
    const {contacts, userId, contactsLength} = useSelector(state => state.profile);
    const selectedStyles = useGmailListItemStyles({
        color: '#da3125',
        margin: "5px !important",
        collapsed: false
    });
    const styles = useGmailListItemStyles({
        collapsed: false,
        margin: "5px !important",
    });

    const addReservationToShow = async (book) => {
        setShowBook(book)
        setToShow(true)
    }

    const newBtn = (index, book, opt) => {

        const btnDecline = () => {
            return (
                <IconButton
                    aria-label="settings"
                    size="small"
                    disabled={load}
                    sx={btnStyle('red')}
                    onClick={() => setWarnDecline(book.id)}>
                    <small>{opt
                        ? userId === book?.reservation?.auditor_who_reserve_id
                            ? "Annuler l'événement"
                            : "Décliner l'événement"
                        : ""}&nbsp;</small>
                    {load
                        ? <div className="mb-1 mr-1">
                            <Spinner type="grow" size="sm"/>
                        </div>
                        : <RemoveCircleOutlineOutlinedIcon
                            fontSize="medium"
                            sx={{color: "red!important"}}
                        />}

                </IconButton>
            )
        }

        const btnAccept = () => {
            return (
                <Tooltip title="Accepter la prise de contact" placement="top">
                    <IconButton
                        size="small"
                        aria-label="settings"
                        disabled={load}
                        sx={btnStyle('lightGreen')}
                        onClick={() => updateCardContact(book.id, 'accepted')}>
                        <small>{opt ? "Accepter" : ""}&nbsp;</small>
                        {load
                            ? <div className="mb-1 mr-1">
                                <Spinner type="grow" size="sm"/>
                            </div>
                            : <DoneAllIcon sx={{color: "lightGreen!important"}}/>}
                    </IconButton>
                </Tooltip>
            )
        }

        const btnGetPayment = () => {
            return (
                <Tooltip title={"Recevez le paiement après avoir effectuer votre prestation"} placement="left">
                    <IconButton
                        size="small"
                        aria-label="settings"
                        sx={btnStyle('lightGreen')}
                        onClick={() => getRoyalties(book?.reservation.id)}
                        disabled={load}>
                        <small>Encaisser</small>&nbsp;
                        {load
                            ? <div className="mb-1 mr-1">
                                <Spinner type="grow" size="sm"/>
                            </div>
                            : <AddTaskIcon fontSize="medium" sx={{color: "lightGreen!important"}}/>}
                    </IconButton>
                </Tooltip>
            )
        }

        return (
            tabIndex === 0
                ? bookedList ? btnDecline() : <div>{btnAccept()}{btnDecline()}</div>
                : tabIndex === 1
                    ? bookedList ? null
                        : book?.reservation?.status === 'paid' && now.isAfter(new Date(book?.reservation?.event_date))
                            ? btnGetPayment()
                            : ""
                    : ""
        )
    }

    const checkMoreIfActive = (key, dataLength, setActive, bookedLengthTmp) => {
        if (key === 'pending' && (contactsLength[link]?.pending > dataLength || bookedLengthTmp > dataLength)) {
            setActive(true)
        } else if (key === 'accepted' && (contactsLength[link]?.accepted > dataLength || bookedLengthTmp > dataLength)) {
            setActive(true)
        } else if (key === 'declined' && (contactsLength[link]?.declined > dataLength || bookedLengthTmp > dataLength)) {
            setActive(true)
        } else {
            setActive(false)
        }
    }

    const addContactData = (key, data, notBooked) => {
        let tmp = data?.sort(
            // @ts-ignore
            (a, b) => new Date(a.reservation.event_date) - new Date(b.reservation.event_date),
        )
        let contactsTmp = {...contacts}
        contactsTmp[link][key] = tmp
        dispatch(addProfileDataContacts(contactsTmp))
        if (!notBooked) {
            setBooked(tmp)
        }
    }

    const getMore = async (pageNumber, setPageNumber) => {
        if (tabIndex === 0) {
            let lastData = contacts[link]?.pending
            let tmp = await getContact(auditor, pageNumber, 'pending', link)
            if (tmp?.data?.length) {
                setPageNumber(pageNumber)
                let newData = [...lastData, ...tmp?.data]
                checkMoreIfActive('pending', newData?.length, setPendingMoreActive)
                addContactData('pending', newData)
            }
        } else if (tabIndex === 1) {
            let lastData = contacts[link]?.accepted
            let tmp = await getContact(auditor, pageNumber, 'accepted', link)
            if (tmp?.data?.length) {
                setPageNumber(pageNumber)
                let newData = [...lastData, ...tmp?.data]
                checkMoreIfActive('accepted', newData?.length, setPendingMoreActive)
                addContactData('accepted', newData)
            }
        } else {
            let lastData = contacts[link]?.declined
            let tmp = await getContact(auditor, pageNumber, 'declined', link)
            if (tmp?.data?.length) {
                setPageNumber(pageNumber)
                let newData = [...lastData, ...tmp?.data]
                checkMoreIfActive('declined', newData?.length, setPendingMoreActive)
                addContactData('declined', newData)
            }
        }
    }

    const updateContactList = async (status, newDataValue) => {
        await getLength(dispatch, auditor, contactsLength, link)
        if (tabIndex === 0) {
            let lastData = contacts[link]?.pending
            let tmp = lastData?.filter((value) => value?.id !== newDataValue?.id)
            addContactData('pending', tmp)

            if (status === 'accepted') {
                let lastDataAccepted = contacts[link]?.accepted
                lastDataAccepted.push(newDataValue)
                addContactData('accepted', lastDataAccepted, true)
            } else if (status === 'declined') {
                let lastDataDeclined = contacts[link]?.declined
                lastDataDeclined.push(newDataValue)
                addContactData('declined', lastDataDeclined, true)
            }

        } else if (tabIndex === 1 && status === 'accepted') {
            let lastData = contacts[link]?.accepted
            let tmp = lastData?.filter((value) => value?.id !== newDataValue?.id)
            tmp.push(newDataValue)
            addContactData('accepted', tmp)
        }

        setLoad(false)
        if (status === "accepted") {
            toast.success("La prise de contact a été accordée.")
        } else if (status === "finish") {
            toast.success("Montant ajouté à votre compte avec succès.")
        } else {
            bookedList
                ? toast.success("La prise de contact a été annulée.")
                : toast.success("La prise de contact a été déclinée.")
        }
    }

    const updateCardContact = async (bookId, status) => {
        setLoad(true);
        setWarnDecline(false);
        auditor.put("contact_cards/update/" + bookId + "/" + status).then(async (resp) => {
            updateContactList(status, resp.data).then(() => setToShow(false))
        }).catch(error => {
            setLoad(false)
            toast.error(checkErrorMessage(error).message)
        });
    };

    const getRoyalties = async (reservationId) => {
        setLoad(true)
        await auditor.put("reservation/finish/" + reservationId).then(async (resp) => {
            await updateContactList('finish', resp.data)
        }).catch(error => {
            setLoad(false)
            toast.error(checkErrorMessage(error).message)
        });
    }

    const checkData = async (tmpTabIndex) => {
        let dataLength = 0
        let tIndex = tmpTabIndex !== null && tmpTabIndex !== undefined ? tmpTabIndex : tabIndex
        if (tIndex === 0) {
            dataLength = contacts[link]?.pending?.length
            if (dataLength) {
                checkMoreIfActive('pending', dataLength, setPendingMoreActive)
                setBooked(contacts[link]?.pending);
            } else {
                let tmp = await getContact(auditor, pendingPage, 'pending', link)
                checkMoreIfActive('pending', tmp?.data?.length, setPendingMoreActive, tmp['length'])
                addContactData('pending', tmp?.data)
            }
        } else if (tIndex === 1) {
            dataLength = contacts[link]?.accepted?.length
            if (dataLength) {
                checkMoreIfActive('accepted', dataLength, setAcceptedMoreActive)
                setBooked(contacts[link]?.accepted);
            } else {
                let tmp = await getContact(auditor, acceptedPage, 'accepted', link)
                checkMoreIfActive('accepted', tmp?.data?.length, setAcceptedMoreActive, tmp['length'])
                addContactData('accepted', tmp?.data)
            }
        } else {
            dataLength = contacts[link]?.declined?.length
            if (dataLength) {
                checkMoreIfActive('declined', dataLength, setRefusedMoreActive)
                setBooked(contacts[link]?.declined);
            } else {
                let tmp = await getContact(auditor, refusedPage, 'declined', link)
                checkMoreIfActive('declined', tmp?.data?.length, setRefusedMoreActive, tmp['length'])
                addContactData('declined', tmp?.data)
            }
        }
    }

    useEffect(() => {
        if (!booked.length && userId) {
            getLength(dispatch, auditor, contactsLength, link).then(r => null)
            checkData().then((r) => userId && setLoading(false))
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [userId, link]);

    return (
        <Row className="justify-content-center">
            <Modal size="sm" show={warnDecline}>
                <CardHeader style={{width: '100%'}} className="m-auto text-center pt-3">
                    <CardTitle tag="h6">
                        <DoNotDisturbIcon sx={{color: 'silver!important'}}/>
                    </CardTitle>
                </CardHeader>
                <p className="p-2 m-auto text-center">
                    {bookedList
                        ? "Êtes-vous certain de vouloir annuler cet événement ?"
                        : "Êtes-vous certain de vouloir décliner cet événement ?"}
                </p>
                <CardFooter>
                    <Row className="justify-content-end">
                        <IconButton
                            size="small"
                            aria-label="settings"
                            sx={btnStyle('red')}
                            onClick={() => setWarnDecline(false)}>
                            <RemoveDoneIcon sx={{color: "red!important"}}/>
                        </IconButton>
                        <IconButton
                            size="small"
                            aria-label="settings"
                            sx={btnStyle('lightGreen')}
                            onClick={() => updateCardContact(parseInt(warnDecline), 'declined')}>
                            <small>Oui&nbsp;</small>
                            <DoneAllIcon sx={{color: "lightGreen!important"}}/>
                        </IconButton>
                    </Row>
                </CardFooter>
            </Modal>

            <Modal show={toShow}>
                <ContactCard
                    newBtn={newBtn}
                    activeLine={activeLine}
                    ContactCardToChat={showBook}
                    bookedList={bookedList}
                    setToShow={setToShow}/>
            </Modal>

            {loading
                ? <SkeletonNavContactCardList/>
                : <DashboardHeader link={link} checkData={checkData} setToLoad={setToLoad} bookedList={bookedList}/>}

            <Col sm="12" className="m-0 p-0 overflow-auto" style={{height: "65vh"}}>
                <Box style={{height: '100%', width: '100%'}} className="radius-1">
                    <List>
                        {loading || toLoad
                            ? Array.from(Array(6).keys()).map((key, index) =>
                                <ListItem key={key} button>
                                    <SkeletonContactCardHeader/>
                                </ListItem>)
                            : booked?.length
                                ? (<div>
                                    {booked.map((book, index) =>
                                        <ListItem
                                            button
                                            key={index}
                                            className="p-0"
                                            onClick={() => setActiveLine(index + 1)}
                                            classes={activeLine === index + 1 ? selectedStyles : styles}
                                            selected={activeLine === index + 1}>

                                            <ContactCardHeader
                                                book={book}
                                                userId={userId}
                                                bookedList={bookedList}
                                                setShowBook={addReservationToShow}
                                            />

                                        </ListItem>)}

                                    {pendingMoreActive && tabIndex === 0
                                        && <div className="row justify-content-center">
                                            <IconButton
                                                color="primary"
                                                aria-label="directions"
                                                onClick={() => getMore(pendingPage + 1, setPendingPage)}>
                                                <Tooltip title="Voir plus" placement="bottom">
                                                    <ArrowDropDownIcon fontSize="large"/>
                                                </Tooltip>
                                            </IconButton>
                                        </div>}
                                    {acceptedMoreActive && tabIndex === 1
                                        && <div className="row justify-content-center">
                                            <IconButton
                                                color="primary"
                                                aria-label="directions"
                                                onClick={() => getMore(acceptedPage + 1, setAcceptedPage)}>
                                                <Tooltip title="Voir plus" placement="bottom">
                                                    <ArrowDropDownIcon fontSize="large"/>
                                                </Tooltip>
                                            </IconButton>
                                        </div>}
                                    {refusedMoreActive && tabIndex === 2
                                        && <div className="row justify-content-center">
                                            <IconButton
                                                color="primary"
                                                aria-label="directions"
                                                onClick={() => getMore(refusedPage + 1, setRefusedPage)}>
                                                <Tooltip title="Voir plus" placement="bottom">
                                                    <ArrowDropDownIcon fontSize="large"/>
                                                </Tooltip>
                                            </IconButton>
                                        </div>}
                                </div>)
                                : NoDataList("Vide")}
                    </List>
                </Box>
            </Col>
        </Row>
    );
}

export default ContactCardList;
