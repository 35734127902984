import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import dayjs from "dayjs";
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Row} from "reactstrap";
import TextInfoContent from '@mui-treasury/components/content/textInfo';
import {useBlogTextInfoContentStyles} from '@mui-treasury/styles/textInfoContent/blog';
import apis from "../../../config/apis";
import {addWalletTransfers} from "../../../reducer";
import {useWindowSize} from "../../../tools/Tools";
import SkeletonRectangular from "../../skeletonCustom/SkeletonRectangular";
import {styled} from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';

const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        // backgroundColor: theme.palette.common.light,
        color: '#757575',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function Transfers() {

    const dispatch = useDispatch();
    const width = useWindowSize();
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const {transfers} = useSelector(state => state.wallet);
    const auditor = useSelector(state => state.axios.auditor);
    const currency = useSelector(state => state.global.globalCurrencySymbol);

    const getTransfers = (_page) => {

        let p = _page ? _page : page
        auditor.get(apis.transactions + '/' + p).then(resp => {
            dispatch(addWalletTransfers(resp.data))
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        });
    }

    const defaultLabelDisplayedRows = ({from, to, count}) => {
        return (
            <div className="mt-3">
                {from}–{to} de {count !== -1 ? count : 'plus de ' + to}
            </div>
        );
    }

    const checkSubjectString = (subject) => {
        switch (subject) {
            case 'reservation_fee':
                return "Événement";
            case 'orange_money_transfer':
                return "Orange Money";
            case 'bank_transfer':
                return "Virement";
            case 'prestige_fee':
                return "Prestige Money";
            case 'contact_fee':
                return "Contact";
            default:
                return "unknown";
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        getTransfers(newPage);
    };

    useEffect(() => {
        if (!transfers?.length) {
            getTransfers()
        } else {
            setLoading(false);
        }

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [transfers?.length])

    return (
        loading
            ? <SkeletonRectangular width="100%" height={61}/>
            : <div>
                <Row className="justify-content-center p-3">
                    <TextInfoContent
                        useStyles={useBlogTextInfoContentStyles}
                        overline={'Transfert(s)'}
                    />
                </Row>
                {transfers?.length
                    ? <Table
                        sx={{width: '100%'}}
                        size={width < 500 ? "small" : "medium"}
                        aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell></StyledTableCell>
                                <StyledTableCell align="right">Montant</StyledTableCell>
                                {width < 500
                                    ? null
                                    : <StyledTableCell align="right">Source</StyledTableCell>}
                                <StyledTableCell align="right">Status</StyledTableCell>
                                {width < 500
                                    ? null :
                                    <StyledTableCell align="right">Le</StyledTableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {transfers.map((row) => (
                                <StyledTableRow key={row.subject + row.id}>
                                    {/* eslint-disable no-useless-concat */}
                                    <StyledTableCell
                                        scope="row"
                                        component="th"
                                        className={row.increase ? 'text-success' : 'text-secondary'}>
                                        {row.increase ? '+' : '-'}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {row.amount}&nbsp;{currency}
                                    </StyledTableCell>
                                    {width < 500
                                        ? null
                                        : <StyledTableCell align="right">
                                            {checkSubjectString(row.subject)}
                                        </StyledTableCell>}
                                    <StyledTableCell
                                        align="right"
                                        className={row.status === 'success' ? 'text-success' : 'text-secondary'}>
                                        {width < 500
                                            ? <FiberManualRecordIcon
                                                fontSize="small"
                                                sx={{
                                                    color:
                                                        row.status === 'success'
                                                            ? 'lightGreen!important'
                                                            : 'silver!important'
                                                }}/>
                                            : row.status === 'success'
                                                ? 'succès'
                                                : 'En cours'}
                                    </StyledTableCell>
                                    {width < 500
                                        ? null :
                                        <StyledTableCell align="right" disabled={width < 500}>
                                            {dayjs(row.created_at).format("DD-MM-YYYY à HH:mm A")}
                                        </StyledTableCell>}
                                </StyledTableRow>
                            ))}
                        </TableBody>
                        <TableFooter className="border-0">
                            <TablePagination
                                page={page}
                                rowsPerPage={10}
                                count={transfers.length}
                                rowsPerPageOptions={[10]}
                                onPageChange={handleChangePage}
                                labelDisplayedRows={defaultLabelDisplayedRows}
                            />
                        </TableFooter>
                    </Table>
                    : <Row className="justify-content-center">
                        <small>Aucun transfer</small>
                    </Row>}
            </div>
    )
}

export default Transfers;
