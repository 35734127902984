
const initState = {
    id: '',
    title: '',
    country: '',
    cities: [],
    events: [],
    thematics: [],
    description: '',
    price: 0,
    galleries: [],
    hidden: false,
    test: false,
    user_id: 0,
    materials_id: 0,
    special_dates: {},
    number_of_artists: 1,
    refund_policy: 'flexible',
    preparation_time: 0,
    duration_of_the_service: 10,
    travel_expenses: {'from': 0, 'to': 0},
    unit_of_the_preparation_time: {"hours": false, "min": true},
    unit_duration_of_the_service: {"hours": false, "min": true, "song": false, "song(s)": false},
};

const serviceData = (state = initState, action) => {
    switch (action.type) {
        case "ADD_SERVICE_FORM_ID":
            return {
                ...state,
                id: action.data
            };
        case "ADD_SERVICE_FORM_TEST":
            return {
                ...state,
                test: action.data
            };
        case "ADD_SERVICE_FORM_TITLE":
            return {
                ...state,
                title: action.data
            };
        case "ADD_SERVICE_FORM_COUNTRY":
            return {
                ...state,
                country: action.data
            };
        case "ADD_SERVICE_FORM_CITIES":
            return {
                ...state,
                cities: action.data
            };
        case "ADD_SERVICE_FORM_EVENTS":
            return {
                ...state,
                events: action.data
            };
        case "ADD_SERVICE_FORM_THEMATICS":
            return {
                ...state,
                thematics: action.data
            };
        case "ADD_SERVICE_FORM_DESC":
            return {
                ...state,
                description: action.data
            };
        case "ADD_SERVICE_FORM_PRICE":
            return {
                ...state,
                price: action.data
            };
        case "ADD_SERVICE_FORM_GALLERIES":
            return {
                ...state,
                galleries: action.data
            };
        case "ADD_SERVICE_FORM_HIDDEN":
            return {
                ...state,
                hidden: action.data
            };
        case "ADD_SERVICE_FORM_USER_ID":
            return {
                ...state,
                user_id: action.data
            };
        case "ADD_SERVICE_FORM_MAT_ID":
            return {
                ...state,
                materials_id: action.data
            };
        case "ADD_SERVICE_FORM_SPEC_DATE":
            return {
                ...state,
                special_dates: action.data
            };
        case "ADD_SERVICE_FORM_NB_ARTIST":
            return {
                ...state,
                number_of_artists: action.data
            };
        case "ADD_SERVICE_FORM_REFUND_POLICY":
            return {
                ...state,
                refund_policy: action.data
            };
        case "ADD_SERVICE_FORM_PREP_TIME":
            return {
                ...state,
                preparation_time: action.data
            };
        case "ADD_SERVICE_FORM_DUR_SERVICE":
            return {
                ...state,
                duration_of_the_service: action.data
            };
        case "ADD_SERVICE_FORM_TRAVEL_EXP":
            return {
                ...state,
                travel_expenses: action.data
            };
        case "ADD_SERVICE_FORM_UNIT_PREP_TIME":
            return {
                ...state,
                unit_of_the_preparation_time: action.data
            };
        case "ADD_SERVICE_FORM_UNIT_DUR_SERVICE":
            return {
                ...state,
                unit_duration_of_the_service: action.data
            };
        default:
            return state;
    }
};

export default serviceData;
