import React, {useEffect, useRef} from 'react';
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useWindowSize} from "../../tools/Tools";
import ContactCardList from "../contactCards/ContactCardList";


function ContactDemand() {

    const isMounted = useRef(false);
    const navigate = useNavigate();
    const width = useWindowSize();
    const role = useSelector(state => state.profile.role);
    const isAuditor = !role ? true : role === "professional_auditor";

    useEffect(() => {
        isAuditor && navigate('/')

        return () => {
            isMounted.current = true
        };
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [isAuditor, navigate])

    return (
        <div className={`content ${width <= 764 ? "pt-5 mx-2" : "pt-1 mx-5"}`}>
            <ContactCardList/>
        </div>
    )
}

export default ContactDemand;
