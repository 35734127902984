import Box from "@material-ui/core/Box";
import CardContent from "@material-ui/core/CardContent";
import CloseIcon from "@mui/icons-material/Close";
import ForumIcon from '@mui/icons-material/Forum';
import BottomNavigation from "@mui/material/BottomNavigation";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import DeleteIcon from '@mui/icons-material/Delete';
import React, {useEffect, useState} from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {IconButton} from "@mui/material";
import LaunchIcon from '@mui/icons-material/Launch';
import Badge from "@mui/material/Badge";
import Tooltip from "@mui/material/Tooltip";
import TextInfoContent from '@mui-treasury/components/content/textInfo';
import {useN04TextInfoContentStyles} from '@mui-treasury/styles/textInfoContent/n04';
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import Divider from "@material-ui/core/Divider";
import {useDispatch, useSelector} from "react-redux";
import NotificationsIcon from '@mui/icons-material/Notifications';
import {useNavigate} from "react-router-dom";
import {CardBody, CardFooter, CardHeader, Row} from "reactstrap";
import {sessionService} from "redux-react-session";
import {
    addProfileNotificationRead,
    addProfileNotificationUnread,
    setContactToChat
} from "../../reducer";
import NoDataList from "../../tools/NoDataList";
import NotificationMsg from "../../tools/NotificationMsg";
import NotifyTitle from "../../tools/NotifyTitle";
import {btnStyle, checkUnReadLen, notificationCard, useWindowSize} from "../../tools/Tools";
import Drawer from '@mui/material/Drawer';

function Notify() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const width = useWindowSize();
    const [loading, setLoading] = useState(false);
    const [readPage, setReadPage] = useState(1);
    const [toShow, setToShow] = useState({});
    const [unReadPage, setUnreadPage] = useState(1);
    const [notifyTheme, setNotifyTheme] = useState('unread');
    const read = useSelector(state => state.profile.notificationRead);
    const unReadLen = useSelector(state => state.profile.notification_length);
    const [readMoreActive, setReadMoreActive] = useState(true);
    const [unReadMoreActive, setUnReadMoreActive] = useState(true);
    const auth = useSelector(state => state.session.authenticated);
    const unRead = useSelector(state => state.profile.notificationUnRead);
    const auditor = useSelector(state => state.axios.auditor);
    const contactCardToEvents = useSelector(state => state.global.contactCardToEvents);
    const [state, setState] = useState({right: false});

    const openR = async () => {
        toCloseNotify()
        navigate('/dashboard')
    }

    const openDiscus = async () => {
        let ContactCardToChat;
        if (toShow?.type === "contact_card_notify") {
            ContactCardToChat = contactCardToEvents.filter((value) => value.id === toShow.element_id)[0]
        } else {
            ContactCardToChat = contactCardToEvents.filter((value) => value.reservation.id === toShow.element_id)[0]
        }
        toCloseNotify()
        await dispatch(setContactToChat(ContactCardToChat))
        document.getElementById("open_discussion").click()
    }

    const notifyToRead = async (notify_id) => {
        await auditor.put("notifications/read/" + notify_id).then(async () => {
            await dispatch(addProfileNotificationUnread([...unRead.filter((value) => value.id !== notify_id)]))
            await checkRead(false).then(() => null)
            await checkUnReadLen(auditor, dispatch)
        })
    }

    const checkUnread = async (more: boolean) => {
        let page_number = more ? unReadPage + 1 : unReadPage;
        await sessionService.loadSession().then(async (currentSession) => {
            await auditor
                .get(
                    'notifications/per_page/' + page_number.toString() + '/0',
                    {headers: {'Isl-Token': currentSession.token}})
                .then(async (rsp) => {
                    let nt = rsp.data;
                    nt.length !== 20 && setUnReadMoreActive(false);
                    if (more) {
                        setUnreadPage(page_number);
                        await dispatch(addProfileNotificationUnread([...unRead, ...nt]))
                    } else {
                        await dispatch(addProfileNotificationUnread(nt))
                    }
                });
        }).catch(() => {
            setUnReadMoreActive(false)
            // HomeRoot.notOnline();
        });

    };

    const checkRead = async (more: boolean) => {
        let page_number = more ? readPage + 1 : readPage;
        await sessionService.loadSession().then(async (currentSession) => {
            auditor.defaults.headers.common['Isl-Token'] = currentSession.token
            await auditor
                .get(
                    'notifications/per_page/' + page_number.toString() + '/1',
                    {headers: {'Isl-Token': currentSession.token}})
                .then(async (rsp) => {
                    let nt = rsp.data;
                    nt.length !== 20 && setReadMoreActive(false);
                    if (more) {
                        setReadPage(page_number);
                        await dispatch(addProfileNotificationRead([...read, ...nt]))
                    } else {
                        await dispatch(addProfileNotificationRead(nt))
                    }
                });
        }).catch(() => {
            setReadMoreActive(false)
            // HomeRoot.notOnline();
        });
    };

    const handleChange = async (event, newValue) => {
        await setNotifyTheme(newValue);
    };

    const toCloseNotify = (isOpen) => {
        Object.keys(toShow)?.length && !toShow.read && notifyToRead(toShow?.id)
        setToShow({})
        if (isOpen) {
            setState({...state, 'right': false});
        }
    }

    const deleteNotification = async (id, _read) => {
        setLoading(true);
        id && await auditor.delete("notifications/delete/" + id).then(async () => {
            if (_read) {
                await dispatch(addProfileNotificationRead([...read.filter((value) => value.id !== id)]))
            } else {
                await dispatch(addProfileNotificationUnread([...unRead.filter((value) => value.id !== id)]))
            }
            toCloseNotify(false)
            await checkUnReadLen(auditor, dispatch)
            toast.success("supprimé")
            setLoading(false);
        })
    }

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({...state, [anchor]: open});
    };

    const openNotify = (notify) => {
        setToShow(notify)
        setState({...state, 'right': false});
    }

    useEffect(() => {
        if (auth && !unRead?.length && !read?.length) {
            Promise.all([
                checkRead(false).then(() => null),
                checkUnread(false).then(() => null),
            ]).then(() => setLoading(false));
        } else {
            setLoading(false);
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [auth]);

    return (
        <div>
            <Tooltip title="Notifications" placement="bottom">
                <IconButton
                    id="notification_open"
                    className="border-right border-bottom"
                    sx={{ml: 0.5, backgroundColor: 'background.paper'}}
                    onClick={toggleDrawer('right', true)}>
                    <Badge badgeContent={unReadLen} color="secondary" invisible={!unReadLen}>
                        <NotificationsIcon/>
                    </Badge>
                </IconButton>
            </Tooltip>

            <Modal size="md" show={toShow?.id}>
                <CardHeader>
                    <p className="p-2 m-auto text-center">
                        {NotifyTitle(toShow?.type, toShow?.subject)}
                    </p>
                </CardHeader>
                <CardBody>
                    <Box>
                        <CardContent>
                            <TextInfoContent
                                classes={{text: 'justify'}}
                                useStyles={useN04TextInfoContentStyles}
                                overline={NotificationMsg(toShow)?.title}
                                body={NotificationMsg(toShow)?.message}
                            />
                        </CardContent>
                    </Box>
                </CardBody>
                <CardFooter>
                    <Row className="justify-content-end">
                        <IconButton
                            aria-label="settings"
                            size="small"
                            sx={btnStyle('gray')}
                            onClick={() => {
                                setState({...state, 'right': true});
                                toCloseNotify(false)
                            }}
                        >
                            <CloseIcon sx={{color: "gray!important"}}/>
                        </IconButton>
                        <IconButton
                            aria-label="settings"
                            size="small"
                            sx={btnStyle('red')}
                            onClick={() => deleteNotification(toShow?.id, toShow?.read)}
                        >
                            <DeleteIcon sx={{color: "red!important"}}/>
                        </IconButton>
                        <IconButton
                            aria-label="settings"
                            size="small"
                            sx={btnStyle(
                                toShow?.type === "contact_card_notify" && ['pending', 'declined'].includes(toShow?.subject)
                                    ? 'orange'
                                    : 'blue'
                            )}
                            onClick={() =>
                                toShow?.type === "contact_card_notify"
                                && ['pending', 'declined'].includes(toShow?.subject)
                                    ? openR()
                                    : openDiscus()}
                        >
                            {toShow?.type === "contact_card_notify" && ['pending', 'declined'].includes(toShow?.subject)
                                ? <LaunchIcon fontSize="small" sx={{color: "orange!important"}}/>
                                : <ForumIcon fontSize="small" sx={{color: "blue!important", marginTop: 0.5}}/>}
                        </IconButton>
                        {/*<div style={{margin: 12, paddingTop: 3}} hidden={!process}>*/}
                        {/*    <ImpulseSpinner size={50} frontColor="#f5f5f5"/>*/}
                        {/*</div>*/}
                    </Row>
                </CardFooter>
            </Modal>

            <Drawer
                anchor={'right'}
                open={state['right']}
                onClose={toggleDrawer('right', false)}
            >

                <Box sx={{width: width < 500 ? width : 450}} role="presentation">
                    <div className={width > 500 ? "justify-content-center" : null}>
                        <TextInfoContent
                            useStyles={useN04TextInfoContentStyles}
                            heading={width > 500 ? 'Notifications' :
                                <div className={"row m-3 justify-content-between"}>
                                    Notifications
                                    <InputAdornment position="start" sx={{mt: 4}}>
                                        <Button
                                            id="close_notification"
                                            variant="outlined"
                                            onClick={toggleDrawer('right', false)}
                                            endIcon={<CloseIcon/>}>
                                            ESC
                                        </Button>
                                    </InputAdornment>
                                </div>}
                        />
                    </div>
                    <div className="row justify-content-center">
                        <BottomNavigation style={{width: '100%'}}
                                          className="mb-3"
                                          showLabels
                                          value={notifyTheme}
                                          onChange={handleChange}>
                            <BottomNavigationAction
                                label="Non Lu(s)"
                                value="unread"
                                icon={<NotificationsIcon/>}
                            />
                            <BottomNavigationAction
                                label="Déjà Lu(s)"
                                value="read"
                                icon={<NotificationsNoneIcon/>}
                            />
                        </BottomNavigation>
                        <Divider sx={{height: 12.5, width: '100%'}} orientation="horizontal"/>
                    </div>
                    <div className="mt-1">
                        {notifyTheme === 'unread'
                            ? <div>
                                <Divider sx={{width: 80}} orientation="horizontal"/>
                                <div className="overflow-auto"
                                     style={{'maxHeight': '78vh', background: 'rgba(160,237,199,0.16)'}}>
                                    {unRead.length
                                        ? <div>
                                            {
                                                unRead.map((notify, index) =>
                                                    <Tooltip title="Cliquer pour afficher" placement="left"
                                                             key={index + "_unRead"}>
                                                        <IconButton
                                                            className="width-full border-bottom"
                                                            aria-label="settings">
                                                            {notificationCard(notify, index, loading, width, openNotify)}
                                                        </IconButton>
                                                    </Tooltip>)
                                            }
                                            {unReadMoreActive
                                                && <div className="row justify-content-center">
                                                    <IconButton
                                                        color="primary"
                                                        aria-label="directions"
                                                        onClick={() => checkUnread(true)}>
                                                        <Tooltip title="Voir plus" placement="bottom">
                                                            <ArrowDropDownIcon fontSize="large"/>
                                                        </Tooltip>
                                                    </IconButton>
                                                </div>}
                                        </div>
                                        : NoDataList("Aucune notification")
                                    }
                                </div>
                            </div>
                            : <div>
                                <Divider sx={{width: 80}} orientation="horizontal"/>
                                <div className="overflow-auto"
                                     style={{'maxHeight': '78vh', background: 'rgb(244,240,240)'}}>
                                    {read.length
                                        ? <div>
                                            {
                                                read.map((notify, index) =>
                                                    <Tooltip title="Cliquer pour afficher" placement="left"
                                                             key={index + "_read"}>
                                                        <IconButton
                                                            className="width-full border-bottom"
                                                            aria-label="settings">
                                                            {notificationCard(notify, index, loading, width, openNotify)}
                                                        </IconButton>
                                                    </Tooltip>)
                                            }
                                            {readMoreActive
                                                && <div className="row justify-content-center">
                                                    <IconButton
                                                        color="primary"
                                                        aria-label="directions"
                                                        onClick={() => checkRead(true)}>
                                                        <Tooltip title="Voir plus" placement="bottom">
                                                            <ArrowDropDownIcon fontSize="large"/>
                                                        </Tooltip>
                                                    </IconButton>
                                                </div>}
                                        </div>
                                        : NoDataList("Aucune notification")
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </Box>

            </Drawer>

        </div>
    );
}

export default Notify;
