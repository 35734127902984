import NearbyErrorIcon from '@mui/icons-material/NearbyError';
function ErrorMsg(message) {

    switch (message) {
        case 'already declined':
            return {"error": true, message: 'Réservation déja decliné.'}
        case 'stripe_token: Not a valid mapping type.':
            return {"error": true, message: 'Cartographie stripe invalide.'}
        case 'card_declined':
            return {"error": true, message: 'Le paiement a été refusé.'}
        case 'Active your account':
            return {"error": true, message: 'Veuillez activer votre compte.'}
        case 'invalid password':
        case 'invalid email or password':
            return {"error": true, message: 'E-mail ou mot de passe incorrect.'}
        case 'Service not found':
            return {"error": true, message: 'La fiche KantoBiz est introuvable.'}
        case 'authentication_required':
            return {"error": true, message: 'Besoin d\'authentification.'}
        case 'The event date can only be greater than today':
            return {"error": true, message: 'Date d\'événement non possible.'}
        case 'new_proposition not found or proposition than lower':
            return {"error": true, message: 'Votre proposition est inférieur au montant de départ.'};
        case 'payout failed':
            return {
                "error": true, message: <div>
                    <p>Erreur lors du tranfert&nbsp;<NearbyErrorIcon /></p>
                    <p className="text-secondary">Voici quelques raisons énumérées :</p>
                    <ul>
                        <li><small>Le compte bancaire n'a pas pu être localisé.</small></li>
                        <li><small>Le compte bancaire du client a été clôturé.</small></li>
                        <li><small>Vous avez informer votre banque que ce paiement n'était pas autorisé.</small></li>
                    </ul>
                </div>
            };
        default:
            return {"error": true, message: message};
    }
}

export default ErrorMsg;
