import Box from "@material-ui/core/Box";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import {useGmailTabItemStyles, useGmailTabsStyles} from "@mui-treasury/styles/tabs";
import CloseIcon from "@mui/icons-material/Close";
import ForumIcon from "@mui/icons-material/Forum";
import NearbyErrorIcon from "@mui/icons-material/NearbyError";
import {IconButton} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import React, {useEffect, useState} from "react";
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import {CardFooter, Row} from "reactstrap";
import CelebrationIcon from '@mui/icons-material/Celebration';
import InfoIcon from '@mui/icons-material/Info';
import {setContactToChat} from "../../reducer";
import {btnStyle, useWindowSize} from "../../tools/Tools";
import EventToReserve from "../reservations/EventToReserve";
import ContactCardInfos from "./ContactCardInfos";
import ContactInvoice from "./ContactInvoice";
import ReceiptIcon from '@mui/icons-material/Receipt';

const indicatorColors = ['#ed1c24', '#ed1c24', '#ed1c24', '#ed1c24'];

function ContactCard({ContactCardToChat, bookedList, setToShow, activeLine, newBtn}) {

    const dispatch = useDispatch();
    const width = useWindowSize();
    const tabsStyles = useGmailTabsStyles({indicatorColors});
    const tabItemStyles = useGmailTabItemStyles({color: '#ed1c24'});
    const [tabIndex, setTabIndex] = useState(0);
    const [address, setAddress] = useState({});
    const countries = useSelector(state => state.global.countries);

    const splitAddress = () => {
        let [street, pcCity, country] = ContactCardToChat?.reservation?.address?.split(', ')
        let [postalCode, city] = pcCity.split(' ')
        setAddress({street, postalCode, country, city})
    }


    const checkDiscuss = async () => {
        if (ContactCardToChat?.status === 'accepted' && !ContactCardToChat?.aborted && ContactCardToChat?.reservation?.status !== 'declined') {
            if ((new Date(ContactCardToChat?.reservation?.event_date) >= new Date()) && !ContactCardToChat?.reservation.aborted) {
                setToShow(false)
                await dispatch(setContactToChat(ContactCardToChat))
                document.getElementById("open_discussion").click()
            } else {
                toast("La discussion a été clôturée en raison de l'état de l'événement, soit qu'il a été annulé, soit qu'il est déjà passé.", {
                    icon: <NearbyErrorIcon sx={{color: 'orange!important'}}/>,
                });
            }
        } else if (ContactCardToChat?.status === 'pending') {
            toast("La discussion sera initiée une fois que l'artiste aura donné son accord suite à la prise de contact.", {
                icon: <NearbyErrorIcon/>,
            });
        } else if (ContactCardToChat?.status === 'declined') {
            toast("La discussion a été close suite au désaccord de l'artiste lors de la prise de contact.", {
                icon: <NearbyErrorIcon/>,
            });
        } else if (ContactCardToChat?.aborted) {
            toast("La discussion a été close suite à l'abandon de l'événement.", {
                icon: <NearbyErrorIcon/>,
            });
        }
    }

    useEffect(() => {
        splitAddress()
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);

    return (
        <div>
            <CardFooter>
                <Tabs
                    value={tabIndex}
                    classes={tabsStyles}
                    onChange={(e, index) => setTabIndex(index)}
                    className="radius-top-1 border-bottom-0"
                    TabIndicatorProps={{
                        children: <div className={`MuiIndicator-${tabIndex}`}/>,
                    }}
                >
                    <Tab
                        wrapped
                        label="Événement"
                        classes={tabItemStyles}
                        icon={<CelebrationIcon/>}
                    />
                    <Tab
                        wrapped
                        label="Facture"
                        classes={tabItemStyles}
                        icon={<ReceiptIcon/>}
                    />
                    <IconButton onClick={() => setTabIndex(2)}>
                        <InfoIcon/>
                    </IconButton>
                </Tabs>
            </CardFooter>
            <Box className="row justify-content-center">
                {!tabIndex
                    ? <EventToReserve
                        address={address}
                        ContactCardToChat={ContactCardToChat}
                        countries={countries}/>
                    : tabIndex === 1
                        ? <ContactInvoice
                            address={address}
                            bookedList={bookedList}
                            ContactCardToChat={ContactCardToChat}
                            countries={countries}/>
                        : <ContactCardInfos
                            bookedList={bookedList}
                            ContactCardToChat={ContactCardToChat}/>}
            </Box>
            <CardFooter>
                <Row className="justify-content-end">
                    <Tooltip title="Fermer cette onglet" placement="top">
                        <IconButton
                            aria-label="settings"
                            size="small"
                            sx={btnStyle('gray')}
                            onClick={() => setToShow(false)}>
                            <CloseIcon sx={{color: "gray!important"}}/>
                        </IconButton>
                    </Tooltip>
                    <div className={width < 500 ? "col justify-content-end" : ""}>
                        {newBtn(activeLine, ContactCardToChat, true)}
                    </div>
                    <Tooltip title="Ouvrir la discussion" placement="top">
                        <IconButton
                            size="small"
                            aria-label="settings"
                            sx={btnStyle(
                                ContactCardToChat?.status === 'accepted' && !ContactCardToChat?.aborted && ContactCardToChat?.reservation?.status !== 'declined'
                                    ? 'green'
                                    : ContactCardToChat?.status === 'declined' || ContactCardToChat?.aborted || ContactCardToChat?.reservation?.status === 'declined'
                                        ? 'red'
                                        : ContactCardToChat?.status === 'pending'
                                            ? 'gray'
                                            : ContactCardToChat?.status === 'paid'
                                                ? 'blue'
                                                : 'gray'
                            )}
                            onClick={() => checkDiscuss()}>
                            <ForumIcon sx={
                                ContactCardToChat?.status === 'accepted' && !ContactCardToChat?.aborted && ContactCardToChat?.reservation?.status !== 'declined'
                                    ? {color: 'lightGreen!important'}
                                    : ContactCardToChat?.status === 'declined' || ContactCardToChat?.aborted || ContactCardToChat?.reservation?.status === 'declined'
                                        ? {color: 'red!important'}
                                        : ContactCardToChat?.status === 'pending'
                                            ? {color: 'gray!important'}
                                            : ContactCardToChat?.status === 'paid'
                                                ? {color: 'blue!important'}
                                                : {color: 'gray!important'}
                            }/>
                        </IconButton>
                    </Tooltip>
                </Row>
            </CardFooter>
        </div>
    )
}

export default ContactCard;
